<template>
  <section class="data-panel">
    <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
      <template v-if="mode == 'editor'">
        <DashboardPanelHeader
          ref="panelHeader"
          :panel="panel"
          :mode="mode"
          :isEditing="isEditing"
          :equipment="equipment"
          customBoxHeaderClasses="with-border"
          @title:click="$emit('panelProperties')"
        >
          <template #toolbar><slot name="toolbar"></slot></template>
        </DashboardPanelHeader>
      </template>
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs pull-right ui-sortable-handle"
          v-if="mode != 'editor'"
        >
          <!-- /Tabs -->
          <li
            v-for="(tab, index) in visibleTabs"
            v-bind:key="index"
            v-bind:class="{active: tab.name == tabs.active}"
            v-on:click.prevent.stop="setTab(tab.name)"
            :title="$t(`titles.${tab.name}`)"
          >
            <a
              class="equipment-data-panel-tab"
              href="javascript:void(0);"
              aria-expanded="true"
            >
              <i v-bind:class="tab.icon"></i>

              <template v-if="tab.tab_name">
                {{ $t(`${tab.tab_name}`) }}
              </template>
            </a>
          </li>
          <!-- \tabs -->

          <!-- /Toolbar -->
          <li class="pull-left header">
            <DashboardPanelTitle :panel="panel" :connector="equipment" />
            <slot name="toolbar"></slot>
          </li>
          <!-- \Toolbar -->
        </ul>
        <div class="tab-content panel-content">
          <div class="tab-pane active" v-if="activeTab && isReady">
            <component
              v-if="hasData"
              :is="WIDGET"
              v-model="selected"
              :sortDef="sortDef"
              :dataList="equipmentDataList"
              :display="display"
              :fields="fields"
              :equipment="equipment"
              :selected="selected"
              :widgetOptions="widgetOptions"
              :writableDataSelection="writableDataSelection"
              :panel="panel"
              :refreshTimerEnabled="refreshTimerEnabled"
              :screenId="screenId"
              @editDataArray="onEditDataArray"
              @edit="onEdit"
              @save="onSave"
              @hide="onPanelHide"
              @select="onSelect"
              @refreshList="onRefreshList"
              @widthError="onWidthError"
              @postSave="onPostSave"
            >
            </component>
            <div v-else>
              <table
                class="table table-condensed table-bordered table-hover dataTable table-striped table-responsive"
              >
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ $t("there_are_no_data_to_show") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Spin v-if="busy" />
    </div>

    <EquipmentDataEditorPanel
      v-if="showEditor"
      :panel="panel"
      :selected="selected"
      :equipment="equipment"
      :fields="fields"
      :modal="showEditor"
      v-on:toggle="toggleEditor"
    >
    </EquipmentDataEditorPanel>

    <FormDataArray
      ref="formDataArray"
      v-if="dataArray"
      :modal="true"
      :data="dataArray"
      @save="onSave"
      @close="dataArray = null"
    />
  </section>
</template>

<script>
import EquipmentDataSelectionPanel from "@/components/equipment-data-selection-panel.vue";
import EquipmentDataEditorPanel from "@/components/equipment-data-editor-panel.vue";
import MixinAlert from "@/project/mixin-alert.js";
import Spin from "@/components/spin";
import CommandFormManager from "@/utils/command-form-manager.js";
import ConnectorDataListForm from "@/components/control-sidebar/property-editors/connector-data-list-form.vue";
import FormDataArray from "@/components/registration/form-data-array.vue";
import DashboardPanelTitle from "@/components/dashboard-panel-title.vue";
import DashboardPanelHeader from "@/components/dashboard-panel-header.vue";

const defaultColors = [
  "#9966ff",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#9966ff",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#9966ff",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#9966ff",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#9966ff",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40",
  "#9966ff",
  "#ffce56",
  "#36a2eb",
  "#4bc0c0",
  "#ff9f40"
];

export default {
  name: "EquipmentDataPanel",
  mixins: [MixinAlert],
  components: {
    EquipmentDataSelectionPanel,
    EquipmentDataEditorPanel,
    Spin,
    FormDataArray,
    DashboardPanelTitle,
    DashboardPanelHeader
  },
  props: {
    equipment: Object,
    display: Object,
    panelName: String,
    refreshTimerEnabled: {
      type: Boolean,
      default: false,
      required: false
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    },
    title: {
      type: String,
      default: "equipment_runtime_data_editor",
      required: false
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    },
    panel: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data: () => {
    return {
      tabs: {
        active: "",
        items: [
          {
            name: "tab_editor",
            icon: "fa fa-pencil",
            show_datepicker: false,
            show_download: false,
            require_dataset: false,
            require_selection: false,
            templateName: "EquipmentDataEditorPanel",
            title: "data_configuration",
            tab_name: "multiple_data_write_tab_name",
            configuration: null
          },
          {
            name: "tab_selection",
            icon: "fa fa-filter",
            show_datepicker: false,
            show_download: false,
            require_dataset: false,
            require_selection: false,
            templateName: "EquipmentDataSelectionPanel",
            title: "data_configuration",
            tab_name: null,
            configuration: null
          }
        ]
      },
      busy: false,
      isReady: false,
      startDate: null, // todo: move it to the selection component
      endDate: moment(),
      selected: [],
      showEditor: false,
      dataArray: null
    };
  },
  computed: {
    dashboardMode() {
      return this.$store.getters["dashboard/mode"];
    },
    writableDataSelection() {
      return this.configuredTabs.length == 2 &&
        this.configuredTabs.find(
          (i) => i.templateName == "EquipmentDataEditorPanel"
        )
        ? true
        : false;
    },
    panelOptions() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    configuredPanels() {
      var self = this;
      var options = self.panelOptions;
      if (options && "panels" in options && options.panels.length) {
        return options.panels;
      }
      return null;
    },
    configuredTabs() {
      var self = this;
      var panels = self.configuredPanels || [];
      var templates = panels
        .filter((i) => {
          return "enabled" in i ? i.enabled : true;
        })
        .map((i) => {
          return i.template;
        });
      return self.tabs.items.filter(function(tab) {
        var ix = templates.indexOf(tab.templateName);
        if (ix >= 0) {
          tab.configuration = panels[ix];
          return true;
        } else {
          tab.configuration = null;
          return false;
        }
      });
    },
    widgetOptions() {
      var self = this;
      var tab = self.activeTab;
      return (tab && tab.configuration) || null;
    },
    visibleTabs() {
      var self = this;
      if (!self.hasData) return [];
      var tabs = self.configuredTabs;
      return tabs.filter((tab) => {
        if (tab.require_selection) {
          return self.selected && self.selected.length ? true : false;
        } else {
          return true;
        }
      });
    },
    activeTab() {
      var self = this;
      var tabs = self.configuredTabs;
      if (tabs && tabs.length) {
        var lst = tabs.filter((i) => {
          return i.name == self.tabs.active;
        });
        return (lst && lst.length && lst[0]) || null;
      }
      return null;
    },
    WIDGET() {
      var self = this;
      var components = self.$options.components || {};
      var tab = self.activeTab;
      var tpl = (tab && tab.templateName) || "EquipmentDataSelectionPanel";
      return (
        (tpl in components && components[tpl]) || EquipmentDataSelectionPanel
      );
    },
    startRef() {
      var options = this.panelOptions;
      return moment().add(
        (options && options.history && options.history.interval) || 24,
        (options && options.history && options.history.unit) || "hour"
      );
    },
    fields() {
      var self = this;
      var lst = self?.panelOptions?.data || [];
      if ((!lst || lst.length == 0) && self.selected && self.selected.length) {
        let colors = defaultColors.slice(0);
        lst = self.selectedDataList.map((item) => {
          return {
            data_id: item.id,
            decimals: 0,
            format: "",
            refreshInterval: 0,
            label: item.name,
            selected: true,
            chart: {
              type: "line",
              color: colors.pop(),
              trend: false,
              scale: "left"
            },
            default: 0
          };
        });
      }
      return lst;
    },
    hasData() {
      return this.equipmentDataList.length ? true : false;
    },
    panelDataIdList() {
      return (this?.panelOptions?.dataList || [])
        .filter(({enabled}) => enabled)
        .map(({data_id}) => data_id);
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    equipmentDataList() {
      let lst = this.dataList;
      if (this.panelDataIdList.length) {
        let ix;
        return lst
          .filter((item) => {
            ix = this.panelDataIdList.indexOf(item.id);
            if (ix >= 0) {
              item._ix = ix;
              return true;
            }
            return false;
          })
          .sort((a, b) => a._ix - b._ix);
      } else if (this?.equipment?.id) {
        return this.connectorBasedFilter;
      } else {
        return this.$store.getters["dashboard/dataListFromEquipment"] ?? [];
      }
    },
    customView() {
      const cfg = this?.equipment?.portal_data?.custom_view || null;
      return cfg && (cfg.order_type || (cfg.excluded || []).length)
        ? cfg
        : null;
    },
    sortDef() {
      let cfg = this.customView;
      if (cfg?.order_type == "column") {
        // legacy
        if (cfg.order.column == "timestamp") {
          return {
            ...cfg.order,
            column: "date_time"
          };
        }
        return cfg.order;
      }
      return null;
    },
    connectorBasedFilter() {
      let lst = (this.dataList || []).filter(
        ({clp_id}) => parseInt(clp_id) == parseInt(this?.equipment?.id)
      );
      let cfg = this.customView;
      if (lst.length && cfg) {
        // begin: make it compatible with previous version (devicename+dataname)
        let setReferenceId = (list) => {
          for (var i in list || []) {
            let data = lst.find((item) => {
              return item.device.name + "." + item.name == list[i];
            });
            if (data) {
              list[i] = data.reference_id;
            }
          }
        };
        if (cfg.order_type == "manual") setReferenceId(cfg.order || []);
        setReferenceId(cfg.excluded || []);
        setReferenceId(cfg.checked || []);
        if (cfg.order_type == "manual") {
          lst = lst.sort(
            (a, b) =>
              cfg.order.indexOf(a.reference_id) -
              cfg.order.indexOf(b.reference_id)
          );
        }
        if ((cfg.excluded || []).length) {
          lst = lst.filter((data) => !cfg.excluded.includes(data.reference_id));
        }
      }
      return lst;
    },
    selectedDataList() {
      return this.equipmentDataList.filter(
        ({id}) => this.selected.indexOf(id) >= 0
      );
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    }
  },
  watch: {
    isReady(nval, oval) {
      if (nval && !oval) {
        if (!this.tabs.active) {
          this.setDefaultActiveTab();
        }
      }
    },
    equipment: {
      handler(n) {
        if (n) {
          if (this.hasData) {
            if (!this.isReady) {
              this.fetchEquipmentDataList();
            }
            this.isReady = true;
          } else {
            this.fetchEquipmentDataList();
          }
        }
      },
      deep: true,
      immediate: true
    },
    equipmentDataList: {
      handler(n, o) {
        let prev = o ? o?.length || 0 : 0;
        if (n && (n?.length || 0) != prev) {
          this.$nextTick(() => {
            this.$root.$emit("panel:resized", this.panelName);
          });
          if (
            n.length &&
            this.panelDataIdList.length &&
            n.length == this.panelDataIdList.length
          ) {
            this.isReady = true;
          }
        }
      },
      deep: true,
      immediate: true
    },
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "ConnectorDataListForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: ConnectorDataListForm
            });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    setTab(name) {
      this.tabs.active = name;
    },
    setDefaultActiveTab() {
      var self = this;
      var panels = self.configuredPanels;
      var templateName =
        (panels && panels[0].template) || self.tabs.items[0].templateName;
      var lst = self.tabs.items.filter((i) => {
        return i.templateName == templateName;
      });
      self.tabs.active = lst.length && lst[0].name;
    },
    equipmentDataRequest(ids) {
      var query = {
        resource: "data",
        forceUpdate: true,
        once: true
      };
      if (ids !== undefined && ids.length) {
        query.ids = ids.join(",");
      } else {
        query.connectorId = this.equipment?.id;
        if (this.$store.getters["deviceId"]) {
          query.device_id = this.$store.getters["deviceId"];
        }
      }
      return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    fetchEquipmentDataList() {
      if (this.panelDataIdList.length) {
        this.isReady = true;
        if (!this.tabs.active) {
          this.setDefaultActiveTab();
        }
        return;
      }
      if (!this.refreshTimerEnabled) {
        this.isReady = false;
      }
      this.busy = true;
      this.equipmentDataRequest().then(() => {
        this.busy = false;
        this.isReady = true;
        if (!this.tabs.active) {
          this.setDefaultActiveTab();
        }
      });
    },
    dataSamplesRequest(ids) {
      var query = {
        contract_id: this.$store.getters["user/contract_id"]
      };
      if (ids !== undefined && ids.length) {
        query.data_ids = ids.join(",");
      } else {
        if (this.equipment?.id) {
          query.connector_id = this.equipment?.id;
        }
        if (this.$store.getters["deviceId"]) {
          query.device_id = this.$store.getters["deviceId"];
        }
      }
      return this.$store.dispatch("dashboard/fetchDataSamples", query);
    },
    updateDataSamples(ids) {
      // // 2023-06-15 - due near fair presentation restrictions
      // // all connector related values will be updated
      const connector = this.equipment;
      let delay = parseInt(
        connector?.user_data?.extended_properties
          ?.reading_data_after_write_command_timeout || 0
      );
      if (delay && !isNaN(delay)) {
        setTimeout(
          () => {
            this.$store.dispatch("dashboard/fetchDataState", {ids: ids});
            this.$store.dispatch("dashboard/fetchDataAlarmsState", ids);
            this.dataSamplesRequest();
          },
          delay,
          this
        );
      } else {
        this.$store.dispatch("dashboard/fetchDataState", {ids: ids});
        this.$store.dispatch("dashboard/fetchDataAlarmsState", ids);
        this.dataSamplesRequest();
      }
    },
    toggleEditor() {
      this.showEditor = !this.showEditor;
    },
    onEdit() {
      var self = this;
      var tabs = self.configuredTabs;
      var lst = tabs.filter((i) => {
        return i.templateName == "EquipmentDataEditorPanel";
      });
      if (lst.length) {
        self.tabs.active = lst[0].name;
      } else {
        this.showEditor = true;
      }
    },
    onSelect() {
      this.showEditor = (this.selected && this.selected.length) || false;
    },
    onRefreshList() {
      this.fetchEquipmentDataList();
    },
    onPanelHide() {
      this.tabs.active = this.configuredTabs[
        this.configuredTabs.length - 1
      ].name;
    },
    getInputControls() {
      const _dataList = this.equipmentDataList;
      const _dataValueCurrentIndex = (data) => {
        let dataValueIndex = data?.portal_data?.data_value_index || null;
        if (!((data.memory_size || 1) > 1) || !dataValueIndex) return -1;
        let vlr = -1;
        if (dataValueIndex.type == "constant") {
          vlr = dataValueIndex.value === "" ? -1 : dataValueIndex.value ?? -1;
        } else if (dataValueIndex.data_id) {
          let data = _dataList.find(
            ({id}) => parseInt(id) == parseInt(dataValueIndex.data_id)
          );
          vlr = (data && data?.current_value?.value) ?? -1;
        }
        return vlr >= 0
          ? vlr +
              parseInt(
                dataValueIndex?.offset === "" ? 0 : dataValueIndex?.offset ?? 0
              )
          : -1;
      };

      return (this.formData || []).map((field) => ({
        control: {
          formName: this?.panel?.name || "form1"
        },
        dataValueCurrentIndex: _dataValueCurrentIndex(field.data),
        lastData: field.data,
        isDirty: (self) => self.iValue != self.lastData?.current_value?.value,
        iValue: field.value
      }));
    },
    onSave(formData) {
      if (this.dashboardMode == "editor") {
        (formData || []).forEach((item) => {
          this.$store.dispatch("dashboard/setDataValue", {
            data_id: item.data_id,
            value: item.value
          });
        });
        return;
      }
      if (!(formData && formData.length)) return;
      var self = this;
      self.validatePrivileges("manage", "DadoEscrita").then((isOk) => {
        if (!isOk) return;
        this.formData = formData; // non reactive;
        this._formManager.submit(this?.panel?.name || "form1");
        if (this.$refs.formDataArray && this.dataArray) {
          this.$refs.formDataArray.close();
        }
        this.$store.dispatch("dashboard/setDirtyForms", {
          [this?.panel?.name || this.$options.name]: false
        });
        return;
      });
    },
    setSideBar() {
      if (this.sidebar.name != "ConnectorDataListForm") {
        this.$root.$emit("controlSidebar:setContent", ConnectorDataListForm);
      }
    },
    onWidthError(hasError) {
      if (hasError) {
        // this.$el.style.height = hasError ? "1px" : "";
      }
    },
    onPostSave() {
      this.setTab("tab_selection");
    },
    onEditDataArray(item) {
      this.dataArray = JSON.parse(JSON.stringify(item));
    }
  },
  mounted() {
    var self = this;
    self.startDate = self.startRef;
    //==============================================
    //self.$set(self, 'fields', self.panelOptions.data||[]);
    //==============================================
    var selected = self.fields
      .filter((i) => {
        return ("selected" in i && i.selected) || false;
      })
      .map((i) => {
        return i.data_id;
      });
    self.$set(self, "selected", selected);
  },
  created() {
    this._formManager = new CommandFormManager(this, false);
  },
  beforeDestroy() {
    this._formManager =
      (this._formManager && this._formManager.destroy()) || null;
  }
};
</script>

<style scoped>
.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
  height: 100%;
}

.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
  /* height: calc(100% - 40px); */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.nav-tabs-custom > .tab-content {
  padding: 5px;
}

.tab-toolbar-control-group {
  float: right;
  margin-left: 10px;
}
.tab-toolbar-control {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}
.panel-content {
  position: relative;
  overflow: hidden;
  flex: 1;
}
.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}
.tab-pane {
  height: 100%;
}
.tab-pane > * {
  height: inherit;
}
</style>
